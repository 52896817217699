.stack:hover {
    color: #0067dc;
    font-weight: 600;
    border-bottom: 4px solid #0067dc;
}

.line-t{
    width: 100%;
    height: 2px;
    background-color: #0067dc;
}

.semi-line-t{
    width: 20px;
    height: 20px;
    background-color: #0067dc;
    border-radius: 50%;
    margin-top: -10px;
    margin-left: 50%;
    transform: translateX(-60%);
}


.tech-img {
    width: 90px;
    height: 90px;
    align-items: center;
    align-self: center;
    margin: 10px;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: scale(0.9);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.tech-item.animate {
    animation: fadeIn 0.5s ease-in-out;
}

.tech-item-html,
.tech-item-css,
.tech-item-javascript,
.tech-item-react,
.tech-item-angular,
.tech-item-rust,
.tech-item-php,
.tech-item-golang,
.tech-item-python,
.tech-item-ruby-on-rails,
.tech-item-java,
.tech-item-dotnet,
.tech-item-django,
.tech-item-anaconda,
.tech-item-tensorflow,
.tech-item-scikitlearn,
.tech-item-jupyter,
.tech-item-burp-suite,
.tech-item-metasploit,
.tech-item-nmap,
.tech-item-wireshark,
.tech-item-ethereum,
.tech-item-solidity,
.tech-item-stellar,
.tech-item-ipfs,
.tech-item-blockchain,
.tech-item-solana,
.tech-item-flutter,
.tech-item-dart,
.tech-item-react-native,
.tech-item-android,
.tech-item-kotlin,
.tech-item-java-lg,
.tech-item-swift,
.tech-item-apple-app-store {
    margin: 10px;
    border-radius: 6px;
}

.tech-item-html { border: 2px solid #E44D26; }
.tech-item-css { border: 2px solid #006EBD; }
.tech-item-javascript { border: 2px solid #E7C82E; }
.tech-item-react { border: 2px solid #01D8FF; }
.tech-item-angular { border: 2px solid #B52E31; }
.tech-item-rust { border: 2px solid #000000; }
.tech-item-php { border: 2px solid #777BB3; }
.tech-item-golang { border: 2px solid #6AD7E5; }
.tech-item-python { border: 2px solid #3E7CAC; }
.tech-item-ruby-on-rails { border: 2px solid #CC0000; }
.tech-item-java { border: 2px solid #E76F00; }
.tech-item-dotnet { border: 2px solid #5C2C90; }
.tech-item-django { border: 2px solid #003D2B; }
.tech-item-anaconda { border: 2px solid #45B34F; }
.tech-item-tensorflow { border: 2px solid #FFBC54; }
.tech-item-scikitlearn { border: 2px solid #F89A36; }
.tech-item-jupyter { border: 2px solid #F37821; }
.tech-item-burp-suite { border: 2px solid #EC6A39; }
.tech-item-metasploit { border: 2px solid #0E6EA9; }
.tech-item-nmap { border: 2px solid #7CBED9; }
.tech-item-wireshark { border: 2px solid #2862A2; }
.tech-item-ethereum { border: 2px solid #545A84; }
.tech-item-solidity { border: 2px solid #1C1C1C; }
.tech-item-stellar { border: 2px solid #7E94B2; }
.tech-item-ipfs { border: 2px solid #65C2CB; }
.tech-item-blockchain { border: 2px solid #65C2CB; }
.tech-item-solana { border: 2px solid #49AC85; }
.tech-item-flutter { border: 2px solid #45D1FD; }
.tech-item-react-native { border: 2px solid #01D8FF; }
.tech-item-dart { border: 2px solid #00B4B9; }
.tech-item-android { border: 2px solid #79BD43; }
.tech-item-kotlin { border: 2px solid #A072D4; }
.tech-item-java-lg { border: 2px solid #F89820; }
.tech-item-swift { border: 2px solid #F05138; }
.tech-item-apple-app-store { border: 2px solid #000000; }

@media screen and (max-width: 768px) {
    .tech-img {
        width: 60px;
        height: 60px;
    }
    
    .stack {
        font-size: 1rem;
    }
    
    .tech-item-html,
    .tech-item-css,
    .tech-item-javascript,
    .tech-item-react,
    .tech-item-angular,
    .tech-item-rust,
    .tech-item-php,
    .tech-item-golang,
    .tech-item-python,
    .tech-item-ruby-on-rails,
    .tech-item-java,
    .tech-item-dotnet,
    .tech-item-django,
    .tech-item-anaconda,
    .tech-item-tensorflow,
    .tech-item-scikitlearn,
    .tech-item-jupyter,
    .tech-item-burp-suite,
    .tech-item-metasploit,
    .tech-item-nmap,
    .tech-item-wireshark,
    .tech-item-ethereum,
    .tech-item-solidity,
    .tech-item-stellar,
    .tech-item-ipfs,
    .tech-item-blockchain,
    .tech-item-solana,
    .tech-item-flutter,
    .tech-item-dart,
    .tech-item-react-native,
    .tech-item-android,
    .tech-item-kotlin,
    .tech-item-java-lg,
    .tech-item-swift,
    .tech-item-apple-app-store {
        margin: 5px;
    }
}
