
  .slide-in {
    animation: slideIn 1s ease-in-out forwards; /* Make sure to include "forwards" */
    opacity: 0;
  }
  
  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateY(-50px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  
.service{
    margin-right: 2rem;
}

.service:hover {
    border-color: #0067dc;
    cursor: pointer;
  }
  
.service:hover h4 {
    color: #0067dc;
  }
  
.service:hover .svg-inline--fa {
    color: #0067dc;
  }

.second-router {
  text-decoration: none;
  color: black;
}
  

@media  (max-width: 767px){
    .service{
        margin-right: 0;
        margin-top: 2rem !important;
    }
}