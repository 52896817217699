.line-d{
    width: 100%;
    height: 2px;
    background-color: #0067dc;
}

.semi-line-d{
    width: 20px;
    height: 20px;
    background-color: #0067dc;
    border-radius: 50%;
    margin-top: -10px;
    margin-left: 50%;
    transform: translateX(-60%);
}
