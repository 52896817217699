.team-img {
    /* width: 250px; */
    height: 250px;
}

a:hover .linkT {
    background-color: #0067dc !important;
}

.card1T {
    width: 330px !important;
    background-color: #ffffff;
    border: 0px solid #0067dc !important;
    box-shadow:0 10px 40px rgba(0,0,0,.5);
    border-radius: 0px !important;
}

.card1T:hover {
    background-image: linear-gradient(270deg, #009EF5, #0067dc);
    /* border: 2px solid #0067dc !important; */
    color: white;
}

.card-img-top {
    border-radius: 0px !important;
}


.card2T {
    width: 330px !important;
    background-color: #ffffff;
    border: 0px solid #0067dc !important;
    box-shadow:0 10px 40px rgba(0,0,0,.5);
    border-radius: 0px !important;
}

.card2T:hover {
    background-image: linear-gradient(270deg, #009EF5, #0067dc);
    /* border: 2px solid #0067dc !important; */
    color: white;
}

.card-text {
    font-size: 14px !important;
}

@media (max-width: 993px) {
    .card2T {
        margin-top: 20px;
    }

    .mainCardT {
        justify-content: center !important;
    }
}