

.back-to-top {
    position: fixed;
    background-color:#0067dc;
    color: #fff;
    border: #0067dc;
    width: 44px;
    height: 44px;
    text-align: center;
    line-height: 1;
    font-size: 16px;
    border-radius: 50%;
    right: 30px;
    bottom: 30px;
    transition: background 0.5s;
    z-index: 11;
  }

  .back-to-top:hover {
    background-color: #0252ad;
    transition: background 0.5s;
  }

  .back-to-top i {
    color: #fff;
  }

  @media (max-width: 768px) {
    .back-to-top {
      bottom: 15px;
    }
  }