.containerr {
    display: flex; /* Display children in a row */
    align-items: flex-start; /* Align children at the top */
  }

  .line1 {
    width: 100%;
    height: 2px;
    background-color: #0067dc;
  }
  
  .blue-line {
    width: 4px; /* Width of the blue line */
    background-color: #009EF5; /* Blue color */
    margin-right: 15px; /* Gap between the line and content */
    height: 60px;
  }
  
  .content {
    font-size: 1.25rem;
    color: #009EF5;
    margin-top: 5px; /* Top margin for the content */
  }
  