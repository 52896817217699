.reviews {
  margin-bottom: 30px;
}

.reviews_heading .borderlinemid {
  width: 80px;
  height: 4px;
  background-color: #0067dc;
  margin: 30px auto 0;
}

.heading-title {
  text-align: center;
  font-weight: 700;
  font-size: 1.8rem;
  margin: 1rem 0;
}

.myCarousel {
  text-align: center;
  padding: 2rem;
  background-color: #015fc9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
  height: 555px;
}

.myCarousel img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin-bottom: 1rem;
}

.myCarousel h3 {
  color: #FFFFFF;
  font-weight: bold;
}
.myCarousel h6 {
  color: #E0E0FF;
  text-transform: uppercase;
}

.myCarousel p {
  font-size: 16px;
  color: #D9D9D9;
  margin: 1rem 0;
  line-height: 1.5;
}

.swiper {
  width: 100%;
  padding: 2rem 0;
}

.swiper-button-next,
.swiper-button-prev {
  color: #009ef5 !important;
}

.swiper-pagination-bullet {
  background: #009ef5;
}

.swiper-pagination-bullet-active {
  background: #0067dc;
}

@media (max-width: 768px) {
  .myCarousel p {
    font-size: 14px;
  }

  .myCarousel img {
    width: 80px;
    height: 80px;
  }

  .heading-title {
    font-size: 1.5rem;
  }
}
