
.footer_link {
    color: #718096;
    font-size: 1.2rem;
    cursor: pointer;
    text-decoration: none;
}
.footer_link:hover {
    color: #0067dc;
}

@media (max-width: 993px) {
    .linksFooter {
        display: none;
        margin-top: 0px !important;
        margin-bottom: 10px;
    }
    .footer_link {
        padding: 5px !important;
        display: flex;
        justify-content: start !important;
    }
    .contactusFooter {
        margin-top: 10px;
    }
    .colContactF p {
        margin-top: 0px !important;
    }
} 

.lineF {
    width: 100%;
    height: 2px;
    background-color: #0067dc;
    margin-top: 30px;
}

.footerLinks {
    width: 35px;
    margin-left: 10px;
    height: 35px;
    background-color: #eeeded;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    display: flex;
    box-shadow: 2px 1px 5px 1px #171818;
}

.fbF:hover {
    background: #4267b2;
    cursor: pointer;
}

.fbF:hover svg g path {
    fill: white;
}

.twitterF:hover {
    background: #1da1f2;
    cursor: pointer;
}

.twitterF:hover svg g path {
    fill: white;
}


.instagramF:hover {
    cursor: pointer;
    background: radial-gradient(circle farthest-corner at 35% 90%,
            #fec564,
            transparent 50%),
        radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%),
        radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%),
        radial-gradient(ellipse farthest-corner at 20% -50%,
            #5258cf,
            transparent 50%),
        radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%),
        radial-gradient(ellipse farthest-corner at 60% -20%,
            #893dc2,
            transparent 50%),
        radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent),
        linear-gradient(#6559ca,
            #bc318f 30%,
            #e33f5f 50%,
            #f77638 70%,
            #fec66d 100%);
}

.instagramF:hover svg g path {
    fill: white;
}

.linkedinF:hover {
    cursor: pointer;
    background: #0072b1 !important;
    fill: white;
}

.linkedinF:hover svg g path {
    fill: white;
}
