.slideA{
    margin-bottom: 30px;
}
.vertical-line{
    width: 2px;
    height: 100px; /* Adjust as needed */
    background-color: black;
    margin: 0 10px;
}

.before-line{
    width: 69rem;
}

@media  (max-width: 1200px){
    .before-line{
        width: 100%;
    }
}

.pheading {
    font-size: 30px;
}