.btnProject {
    justify-content: end;
    padding: 30px 15px 30px 0px;
}

.btnReadMore {
    margin-bottom: 30px;
}

@media (max-width: 768px) {
    .btnReadMore {
        margin-bottom: 30px;
    }
}

@media (max-width: 1199px) {
    .btnReadMore {
        margin-bottom: 30px;
    }
}