.pagebuttons:hover {
  color: navy;
  text-decoration: underline;
}

.navbar-toggler {
  width: 60px;
  height: 50px;
  margin-top: 10px;
}

.navbar {
  position: sticky;
  background-color: white;
  top: 0;
  z-index: 100;
}
/* Add this CSS to your component's stylesheet or a separate CSS file */

.dropdown-menu {
  background-image: linear-gradient(#009ef5, #0067dc);
  --bs-dropdown-padding-y: -0.5 !important;
}


.btnService {
  margin-right: 5px;
}

.btnService:hover {
  color: #0067dc;
}

.btn.show {
  border-color: white;
}

.btn:first-child:active,
:not(.btn-check) + .btn:active {
  border-color: white;
}

/* Style for service icon */
.service-icon {
  margin-right: 5px;
  font-size: 10px;
  display: none;
}

/* Style for list items */
.dropdown-item-li {
  display: flex;
  /* align-items: center; */
  padding: 5px 10px !important;
  transition: background-color 0.3s;
  /* border-color: white; Default border color */
  border-bottom: 1px solid white;
}

/* Style for anchor tags */
.dropdown-item-anchor {
  display: flex;
  /* align-items: center; */
  text-decoration: none;
  transition: color 0.3s;
  cursor: pointer;
}

/* Hover effect */
.dropdown-item-li:hover .dropdown-item-anchor {
  /* font-size: 20px; */
  color: #131414 !important;
  cursor: pointer;
}

/* .dropdown-item-li:hover {
  background-color: #fff;
  -webkit-transition: background-color 0.5s ease-out;
  -moz-transition: background-color 0.5s ease-out;
  -o-transition: background-color 0.5s ease-out;
  transition: background-color 0.5s ease-out;
} */

/* .dropdown-item-anchor:hover {
  color: #000 !important;
} */


.dropdown-item-anchor {
  font-size: 18px;
  color: white;
}

.extra-container {
  display: flex;
}

@media screen and (max-width: 991px) {
  .extra-container {
    display: block !important;
  }
}


@media screen and (max-width: 500px) {
  .mainContactBtn {
    margin-top: 0px !important;
  }
}

