.blog-page {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.blog-header {
  position: relative;
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  overflow: hidden;
}

.header-content {
  position: absolute;
  z-index: 2;
}

.blog-main-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
  filter: brightness(50%);
}

.blog-title {
  font-size: 3rem;
  margin: 0;
}

.blog-title span {
  font-weight: bold;
}

.blog-author {
  font-size: 1.2rem;
  margin-top: 10px;
}

.blog-body {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    padding: 20px;
  }
  
  .toc {
    width: 20%;
    padding: 20px;
    background: #f9f9f9;
    border-right: 1px solid #ddd;
    position: sticky;
    top: 90px;
    height: fit-content;
  }
  
  .toc h3 {
    margin-top: 0;
    font-size: 1.5rem;
    color: #333;
  }
  
  .toc ul {
    list-style: none;
    padding: 0;
  }
  
  .toc ul li {
    margin: 10px 0;
  }
  
  .toc ul li a {
    text-decoration: none;
    color: #0067dc;
    font-size: 1rem;
  }
  
  .toc ul li a:hover {
    text-decoration: underline;
  }
  
  .toc-toggle {
    display: none;
    background: #0067dc;
    color: white;
    border: none;
    padding: 10px;
    font-size: 1rem;
    cursor: pointer;
    margin-bottom: 10px;
    width: 100%;
  }
  
  .blog-container {
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .author-info {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 10px;
  }
  
  .author-img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .author-name {
    margin-top: 12px;
    font-size: 1.2rem;
    color: #fff;
  }
  
  .blog-content {
    max-width: 800px;
    text-align: justify;
    font-size: 18px;
    line-height: 1.6;
    color: #555;
  }
  
  .blog-content h2 {
    color: #0067dc;
    margin-top: 30px;
  }
  
  .blog-text {
    margin-bottom: 20px;
  }
  
  .related-posts {
    margin-top: 40px;
    width: 100%;
    text-align: center;
  }
  
  .related-posts h2 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
  }
  
  .card-container-b {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  
  .card-b {
    width: 30%;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    text-align: center;
  }
  
  .card-img-b {
    width: 100%;
    border-bottom: 1px solid #ddd;
  }
  
  .card-title-b {
    font-size: 1.2rem;
    color: #333;
    padding: 10px;
  }
  
  .card-link-b {
    display: inline-block;
    margin: 10px 0;
    text-decoration: none;
    color: #0067dc;
    font-weight: bold;
  }
  
  .card-link-b:hover {
    text-decoration: underline;
  }
  
  @media (max-width: 767.98px) {
    .blog-page {
      flex-direction: column;
    }
  
    .blog-header {
      height: 300px;
    }
  
    .toc {
      width: 100%;
      border-right: none;
      border-bottom: 1px solid #ddd;
      display: none;
    }
  
    .toc.open {
      display: block;
    }
  
    .blog-container {
      width: 100%;
      padding: 10px;
    }

    .blog-title {
        font-size: 1.8rem;
        margin: 0;
      }
  
    .author-info {
      flex-direction: row;
      align-items: center;
    }
  
    .toc-toggle {
      display: block;
    }
  
    .card-b {
      width: 100%;
    }
  }
  