.contact{
    background: #E9F3FF;
}

.borderlinec{
    width: 80px;
    height: 4px;
    background-color: #0067dc;
    margin-top: 30px;
}

.rounded .svg-inline--fa {
    color: #0067dc;
  }

.inputCt {
    height: 50px;
}

@media  (max-width: 991px){
    .contactRight1{
        margin-left: 0px !important;
        margin-top: 40px !important;
        padding-top: 5px !important;
        padding-left: 0px !important;
        padding-bottom: 10px !important;
    }

    .contactRight2{
        margin-left: 0px !important;
        margin-top: 0px !important;
        padding-top: 0px !important;
        padding-left: 0px !important;
    }
}