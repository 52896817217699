.line{
    width: 100%;
    height: 2px;
    background-color: #0067dc;
    margin-top: 30px;
}

.semi-line{
    width: 20px;
    height: 20px;
    background-color: #0067dc;
    border-radius: 50%;
    margin-top: -10px;
    margin-left: 50%;
    transform: translateX(-60%);
}

.borderline{
    width: 70px;
    height: 6px;
    background-image: linear-gradient(#009EF5, #0067dc  );
    margin-top: 60px;
}
.borderlineb {
    width: 70px;
    height: 6px;
    background-image: linear-gradient(#009EF5, #0067dc  );
    margin-bottom: 60px;
    margin-top: 45px
}

.row {
    padding-left: calc(var(--bs-gutter-x) * 0) !important
}


iframe{
    width:560px;
    height:315px;
}

@media screen and (max-width: 767px){
   iframe{
         width: 100%;
         height: 100%;
   }
}