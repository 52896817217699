.container {
  display: flex;
  flex-direction: column;
}

.reviews_heading {
  display: flex;
  flex-direction: column;
}

.reviews_heading .row {
  display: flex;
  align-items: center;
}

.reviews_heading .col-lg-6,
.reviews_heading .col-md-12 {
  display: flex;
  align-items: start;
}

.text-container {
  align-items: flex-start;
}

.image-container {
  justify-content: center;
}

.web-main-img {
  max-width: 100%;
  height: auto;
}
.web-s2-h1 {
  font-weight: bold;
}

.text {
  margin-top: 10px;
  text-align: justify;
}


.parallelogram-background {
  position: relative;
  display: inline-block; /* Adjust as needed for your layout */
  padding: 8px 0; /* Add vertical padding */
}

.parallelogram-background::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, #0067dc, #009EF5); /* Example gradient background */
  transform: skew(-20deg); /* Create the parallelogram effect */
  z-index: -1; /* Ensure the background is behind the content */
  border-radius: 0px; /* Optional: add rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: add a shadow effect */
}

.web-h1-flex {
  color: white;
  margin: 0; /* Ensure there's no margin if needed */
  transform: skew(-20deg); /* Counteract the skew of the background */
  padding: 0 20px; /* Add horizontal padding */
}

@media (max-width: 767.98px) {
  .reviews_heading .col-md-12 {
    justify-content: center;
    text-align: center;
    margin-bottom: 20px;
  }

  .web-s1-h1 {
      font-size: 22px;
  }

  .web-s2-h1 {
      font-size: 22px;
      font-weight: bold;
  }

  .text-container {
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
  }

  .image-container {
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
  }

  .reviews_heading .col-md-12 img {
    margin-top: 5px;
  }
}
  