
  .title {
    margin-top: 30px;
  }
  .title span {
    font-weight: bold;
  }

  .line-b{
    width: 20%;
    height: 2px;
    background-color: #0067dc;
    margin-bottom: 20px;
}
  
  
  .search-input {
    width: 100%;
    padding: 12px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    outline: none;
    transition: border-color 0.3s ease-in-out;
  }
  
  .search-input::placeholder {
    color: #adadad;
  }
  
  .search-input:focus {
    border-color: #0067dc;
  }

  
  .blog-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 20px;
  }
  
  .blog-card {
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
  }
  
  .blog-card:hover {
    transform: translateY(-5px);
  }
  
  .blog-link {
    display: block;
    text-decoration: none;
    color: inherit;
  }
  
  .blog-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  
  .blog-details {
    padding: 20px;
  }
  
  .blog-details h2 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .blog-details p {
    font-size: 14px;
    color: #666;
    margin-bottom: 10px;
  }
  
  .author-b {
    display: flex;
  }
  
  .author-image {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin-top: 20px;
  }
  
  .page-item {
    margin: 0 5px;
  }
  
  .page-link {
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #ccc;
    color: #333;
    padding: 8px 12px;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease-in-out;
  }
  
  .page-link:hover {
    background-color: #f0f0f0;
  }
  

  @media (max-width: 767.98px) { 

    .line-b{
      width: 45%;
      height: 2px;
      background-color: #0067dc;
      margin-bottom: 20px;
  }
  }