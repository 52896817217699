.wrapper {
    position: relative;
}

.wrapper::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #0067dc;
    bottom: 0;
    left: 0;
}

.bg-gradient1 {
    background-size: 200% 100%;

    border-radius: 0px !important;
    border: 0px solid #0067dc !important;

    border-radius: 50px;
    moz-transition: all .4s cubic-bezier(0.19, 1, 0.22, 1);
    -o-transition: all .4s cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition: all .4s cubic-bezier(0.19, 1, 0.22, 1);
    transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}

.bg-gradient1:hover {
    background-position: 100% 0;
    moz-transition: all .4s cubic-bezier(0.19, 1, 0.22, 1);
    -o-transition: all .4s cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition: all .4s cubic-bezier(0.19, 1, 0.22, 1);
    transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}

.bg-gradient1:focus {
    outline: none;
}

.bg-gradient1.color-2 {
    background-image: linear-gradient(to right, #009ef5, #0067dc, #0067dc, #009ef5);
}



.wrapper::after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #0067dc;
    border-radius: 50%;
    bottom: -10px;
    /* Half of the height */
    left: 50%;
    /* Centered */
    transform: translateX(-90%);
    /* Centered horizontally */
}

.image {
    margin-top: 30px;
}

.img1 {
    width: 100%;
}

@media screen and (max-width: 992px) {
    .wrapper {
        min-height: 55vh;
        margin-top: -55px;
    }

    .image {
        display: none;
    }

    /* .content{
        background: url('./main.png');
    } */
}